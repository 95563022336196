@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */ /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400&display=swap");
@import "~@ng-select/ng-select/themes/default.theme.css";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code, dfn, img, kbd, q, s, samp, strike, tt, var, u, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

b, strong, i, em, mark, small, del, ins, sub, sup {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  border: none;
  padding: 0;
}
input:focus {
  outline: none;
}

button {
  border: none;
}

*:focus {
  outline: none;
}

body > * {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*
  This filepaths for the fonts are based on where these styles are imported.
  In most cases src/styles.scss


  This is currently kept very simple - we only use ttf and we don't use 'real' italic fonts.

  If we have font issues, simply put the font files into a converter and
 */
@font-face {
  font-family: "Poppins-BoldItalic";
  src: url("/assets/fonts/poppins/Poppins-BoldItalic.eot");
  src: url("/assets/fonts/poppins/Poppins-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/poppins/Poppins-BoldItalic.woff2") format("woff2"), url("/assets/fonts/poppins/Poppins-BoldItalic.woff") format("woff"), url("/assets/fonts/poppins/Poppins-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/poppins/Poppins.eot");
  src: url("/assets/fonts/poppins/Poppins.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/poppins/Poppins.woff2") format("woff2"), url("/assets/fonts/poppins/Poppins.woff") format("woff"), url("/assets/fonts/poppins/Poppins.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("/assets/fonts/poppins/Poppins-Bold.eot");
  src: url("/assets/fonts/poppins/Poppins-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/poppins/Poppins-Bold.woff2") format("woff2"), url("/assets/fonts/poppins/Poppins-Bold.woff") format("woff"), url("/assets/fonts/poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Italic";
  src: url("/assets/fonts/poppins/Poppins-Italic.eot");
  src: url("/assets/fonts/poppins/Poppins-Italic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/poppins/Poppins-Italic.woff2") format("woff2"), url("/assets/fonts/poppins/Poppins-Italic.woff") format("woff"), url("/assets/fonts/poppins/Poppins-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("/assets/fonts/poppins/Poppins-Medium.eot");
  src: url("/assets/fonts/poppins/Poppins-Medium.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/poppins/Poppins-Medium.woff2") format("woff2"), url("/assets/fonts/poppins/Poppins-Medium.woff") format("woff"), url("/assets/fonts/poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins-MediumItalic";
  src: url("/assets/fonts/poppins/Poppins-MediumItalic.eot");
  src: url("/assets/fonts/poppins/Poppins-MediumItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/poppins/Poppins-MediumItalic.woff2") format("woff2"), url("/assets/fonts/poppins/Poppins-MediumItalic.woff") format("woff"), url("/assets/fonts/poppins/Poppins-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "OpenSans-SemiBold";
  src: url("/assets/fonts/opensans/OpenSans-SemiBold.eot");
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"), url("/assets/fonts/opensans/OpenSans-SemiBold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/opensans/OpenSans-SemiBold.woff2") format("woff2"), url("/assets/fonts/opensans/OpenSans-SemiBold.woff") format("woff"), url("/assets/fonts/opensans/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "OpenSans-Bold";
  src: url("/assets/fonts/opensans/OpenSans-Bold.eot");
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("/assets/fonts/opensans/OpenSans-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/opensans/OpenSans-Bold.woff2") format("woff2"), url("/assets/fonts/opensans/OpenSans-Bold.woff") format("woff"), url("/assets/fonts/opensans/OpenSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "OpenSans-Light";
  src: url("/assets/fonts/opensans/OpenSans-Light.eot");
  src: local("Open Sans Light"), local("OpenSans-Light"), url("/assets/fonts/opensans/OpenSans-Light.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/opensans/OpenSans-Light.woff2") format("woff2"), url("/assets/fonts/opensans/OpenSans-Light.woff") format("woff"), url("/assets/fonts/opensans/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "OpenSans-Regular";
  src: url("/assets/fonts/opensans/OpenSans-Regular.eot");
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url("/assets/fonts/opensans/OpenSans-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/opensans/OpenSans-Regular.woff2") format("woff2"), url("/assets/fonts/opensans/OpenSans-Regular.woff") format("woff"), url("/assets/fonts/opensans/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/*
  This filepaths for the fonts are based on where these styles are imported.
  In most cases src/styles.scss


  This is currently kept very simple - we only use ttf and we don't use 'real' italic fonts.

  If we have font issues, simply put the font files into a converter and
 */
@font-face {
  font-family: "Poppins-BoldItalic";
  src: url("/assets/fonts/poppins/Poppins-BoldItalic.eot");
  src: url("/assets/fonts/poppins/Poppins-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/poppins/Poppins-BoldItalic.woff2") format("woff2"), url("/assets/fonts/poppins/Poppins-BoldItalic.woff") format("woff"), url("/assets/fonts/poppins/Poppins-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/poppins/Poppins.eot");
  src: url("/assets/fonts/poppins/Poppins.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/poppins/Poppins.woff2") format("woff2"), url("/assets/fonts/poppins/Poppins.woff") format("woff"), url("/assets/fonts/poppins/Poppins.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("/assets/fonts/poppins/Poppins-Bold.eot");
  src: url("/assets/fonts/poppins/Poppins-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/poppins/Poppins-Bold.woff2") format("woff2"), url("/assets/fonts/poppins/Poppins-Bold.woff") format("woff"), url("/assets/fonts/poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Italic";
  src: url("/assets/fonts/poppins/Poppins-Italic.eot");
  src: url("/assets/fonts/poppins/Poppins-Italic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/poppins/Poppins-Italic.woff2") format("woff2"), url("/assets/fonts/poppins/Poppins-Italic.woff") format("woff"), url("/assets/fonts/poppins/Poppins-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("/assets/fonts/poppins/Poppins-Medium.eot");
  src: url("/assets/fonts/poppins/Poppins-Medium.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/poppins/Poppins-Medium.woff2") format("woff2"), url("/assets/fonts/poppins/Poppins-Medium.woff") format("woff"), url("/assets/fonts/poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins-MediumItalic";
  src: url("/assets/fonts/poppins/Poppins-MediumItalic.eot");
  src: url("/assets/fonts/poppins/Poppins-MediumItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/poppins/Poppins-MediumItalic.woff2") format("woff2"), url("/assets/fonts/poppins/Poppins-MediumItalic.woff") format("woff"), url("/assets/fonts/poppins/Poppins-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "OpenSans-SemiBold";
  src: url("/assets/fonts/opensans/OpenSans-SemiBold.eot");
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"), url("/assets/fonts/opensans/OpenSans-SemiBold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/opensans/OpenSans-SemiBold.woff2") format("woff2"), url("/assets/fonts/opensans/OpenSans-SemiBold.woff") format("woff"), url("/assets/fonts/opensans/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "OpenSans-Bold";
  src: url("/assets/fonts/opensans/OpenSans-Bold.eot");
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("/assets/fonts/opensans/OpenSans-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/opensans/OpenSans-Bold.woff2") format("woff2"), url("/assets/fonts/opensans/OpenSans-Bold.woff") format("woff"), url("/assets/fonts/opensans/OpenSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "OpenSans-Light";
  src: url("/assets/fonts/opensans/OpenSans-Light.eot");
  src: local("Open Sans Light"), local("OpenSans-Light"), url("/assets/fonts/opensans/OpenSans-Light.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/opensans/OpenSans-Light.woff2") format("woff2"), url("/assets/fonts/opensans/OpenSans-Light.woff") format("woff"), url("/assets/fonts/opensans/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "OpenSans-Regular";
  src: url("/assets/fonts/opensans/OpenSans-Regular.eot");
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url("/assets/fonts/opensans/OpenSans-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/opensans/OpenSans-Regular.woff2") format("woff2"), url("/assets/fonts/opensans/OpenSans-Regular.woff") format("woff"), url("/assets/fonts/opensans/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/*
to use these varibles in the scss add:
  @import "variables";
to the top of the scss file.
Thi import should be only "variables" not a relative path - your IDE may auto-import it incorrectly.
 */
/*
Colors
 */
/*
Typography
Fallback should be sans-serif
 */
/*
Elements sizing
 */
/*
Box-shadows
Please add to these as more are chosen
 */
/*
Border Radii
 */
/*
Animations
Please only animate efficient properties below:
  Position — transform: translateX(n) translateY(n) translateZ(n);
  Scale — transform: scale(n);
  Rotation — transform: rotate(ndeg);
  Opacity — opacity: n;

see: csstriggers.com for more infomation
Other properties are likely to cause poor quality animation on mobile devices
*/
/*
Break points
 */
/**
This mixin simplifies media queries throughout the application
for example:
respond-to(desktop) {} will only apply styles to wider screens

If another screen size is required it only needs to be added to the breakpoints map above
to become available. You can also modify the breakpoints if req'd.
 */
html,
body {
  height: 100%;
  background: #fff;
  color: #000;
  overflow: hidden;
}

/*
Hide scrollbars where possible
 */
/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.example {
  -ms-overflow-style: none;
}

/*
Typography
 */
div, span, p, blockquote, pre,
a, abbr, acronym, address, big, cite,
ol, ul, li, fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, figure, figcaption, footer, header, hgroup,
menu, nav, summary, input {
  font-family: "Poppins", sans-serif;
}

p {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: 18px;
  color: #5d5d5d;
}

h1, h2, h3, h4, h5, h6 {
  color: #000;
  letter-spacing: normal;
  font-style: normal;
  font-stretch: normal;
}

h1 {
  font-size: 30px;
  font-family: "Poppins-Bold", sans-serif;
  line-height: 1.17;
}

h2 {
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  line-height: 1.17;
}

h3 {
  font-family: "Poppins-Bold", sans-serif;
  font-size: 14px;
  line-height: 1.23;
}

h4 {
  font-size: 14px;
  font-family: "Poppins-Medium", sans-serif;
  line-height: 1.24;
}

h5 {
  font-size: 12px;
  font-family: "Poppins-Bold", sans-serif;
}

h6 {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
}

ul, ol {
  list-style: inside;
  padding-left: 7px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: 18px;
  font-family: "Poppins", sans-serif;
}
ul li, ol li {
  margin-top: 16px;
  font-family: "Poppins" !important;
  color: #262D5F !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

a {
  color: #000;
  border-bottom: 1px solid #000;
  line-height: 1em;
  display: inline-block;
  text-decoration: none;
}

svg {
  fill: transparent;
  stroke: #283583;
  stroke-width: 1px;
  stroke-linecap: round;
  stroke-linejoin: round;
  height: 15px;
  width: 15px;
}

edu-blog-details .innerhtml-class-blog-details p {
  font: normal normal normal 18px/27px Poppins;
  letter-spacing: 0px;
  color: #262D5F;
}
edu-blog-details .innerhtml-class-blog-details ul, edu-blog-details .innerhtml-class-blog-details ol {
  list-style: outside;
}
edu-blog-details .innerhtml-class-blog-details li {
  vertical-align: middle;
}
edu-blog-details .innerhtml-class-blog-details li::marker {
  vertical-align: middle;
}

/*
This file is for changing the styles on the ng-select element.
Any changes to this file will affect ALL instances of ng-select in the application
 */
/*
  This filepaths for the fonts are based on where these styles are imported.
  In most cases src/styles.scss


  This is currently kept very simple - we only use ttf and we don't use 'real' italic fonts.

  If we have font issues, simply put the font files into a converter and
 */
@font-face {
  font-family: "Poppins-BoldItalic";
  src: url("/assets/fonts/poppins/Poppins-BoldItalic.eot");
  src: url("/assets/fonts/poppins/Poppins-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/poppins/Poppins-BoldItalic.woff2") format("woff2"), url("/assets/fonts/poppins/Poppins-BoldItalic.woff") format("woff"), url("/assets/fonts/poppins/Poppins-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/poppins/Poppins.eot");
  src: url("/assets/fonts/poppins/Poppins.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/poppins/Poppins.woff2") format("woff2"), url("/assets/fonts/poppins/Poppins.woff") format("woff"), url("/assets/fonts/poppins/Poppins.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("/assets/fonts/poppins/Poppins-Bold.eot");
  src: url("/assets/fonts/poppins/Poppins-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/poppins/Poppins-Bold.woff2") format("woff2"), url("/assets/fonts/poppins/Poppins-Bold.woff") format("woff"), url("/assets/fonts/poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Italic";
  src: url("/assets/fonts/poppins/Poppins-Italic.eot");
  src: url("/assets/fonts/poppins/Poppins-Italic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/poppins/Poppins-Italic.woff2") format("woff2"), url("/assets/fonts/poppins/Poppins-Italic.woff") format("woff"), url("/assets/fonts/poppins/Poppins-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("/assets/fonts/poppins/Poppins-Medium.eot");
  src: url("/assets/fonts/poppins/Poppins-Medium.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/poppins/Poppins-Medium.woff2") format("woff2"), url("/assets/fonts/poppins/Poppins-Medium.woff") format("woff"), url("/assets/fonts/poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins-MediumItalic";
  src: url("/assets/fonts/poppins/Poppins-MediumItalic.eot");
  src: url("/assets/fonts/poppins/Poppins-MediumItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/poppins/Poppins-MediumItalic.woff2") format("woff2"), url("/assets/fonts/poppins/Poppins-MediumItalic.woff") format("woff"), url("/assets/fonts/poppins/Poppins-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "OpenSans-SemiBold";
  src: url("/assets/fonts/opensans/OpenSans-SemiBold.eot");
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"), url("/assets/fonts/opensans/OpenSans-SemiBold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/opensans/OpenSans-SemiBold.woff2") format("woff2"), url("/assets/fonts/opensans/OpenSans-SemiBold.woff") format("woff"), url("/assets/fonts/opensans/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "OpenSans-Bold";
  src: url("/assets/fonts/opensans/OpenSans-Bold.eot");
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("/assets/fonts/opensans/OpenSans-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/opensans/OpenSans-Bold.woff2") format("woff2"), url("/assets/fonts/opensans/OpenSans-Bold.woff") format("woff"), url("/assets/fonts/opensans/OpenSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "OpenSans-Light";
  src: url("/assets/fonts/opensans/OpenSans-Light.eot");
  src: local("Open Sans Light"), local("OpenSans-Light"), url("/assets/fonts/opensans/OpenSans-Light.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/opensans/OpenSans-Light.woff2") format("woff2"), url("/assets/fonts/opensans/OpenSans-Light.woff") format("woff"), url("/assets/fonts/opensans/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "OpenSans-Regular";
  src: url("/assets/fonts/opensans/OpenSans-Regular.eot");
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url("/assets/fonts/opensans/OpenSans-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/opensans/OpenSans-Regular.woff2") format("woff2"), url("/assets/fonts/opensans/OpenSans-Regular.woff") format("woff"), url("/assets/fonts/opensans/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/*
to use these varibles in the scss add:
  @import "variables";
to the top of the scss file.
Thi import should be only "variables" not a relative path - your IDE may auto-import it incorrectly.
 */
/*
Colors
 */
/*
Typography
Fallback should be sans-serif
 */
/*
Elements sizing
 */
/*
Box-shadows
Please add to these as more are chosen
 */
/*
Border Radii
 */
/*
Animations
Please only animate efficient properties below:
  Position — transform: translateX(n) translateY(n) translateZ(n);
  Scale — transform: scale(n);
  Rotation — transform: rotate(ndeg);
  Opacity — opacity: n;

see: csstriggers.com for more infomation
Other properties are likely to cause poor quality animation on mobile devices
*/
/*
Break points
 */
/**
This mixin simplifies media queries throughout the application
for example:
respond-to(desktop) {} will only apply styles to wider screens

If another screen size is required it only needs to be added to the breakpoints map above
to become available. You can also modify the breakpoints if req'd.
 */
.ng-select {
  padding-bottom: 0 !important;
}
.ng-select .ng-select-container {
  /*hiding it because default styling we don't want
  background: #ffffff;
  border: 1px solid #67AFE1;
  */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #A2A2A2;
  border-radius: 5px;
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  padding-top: 4px;
}
.ng-select .ng-option {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #283583;
}
.ng-select .ng-arrow-wrapper .ng-arrow {
  /*hiding it because default styling we don't want
  border-color: $color-primary-200 transparent transparent;
  */
  border-color: #A2A2A2 transparent transparent;
  border-width: 7px 7px 4px;
  top: 3px;
}

.ng-select.ng-select-opened > .ng-select-container {
  /*hiding it because default styling we don't want
  border: 1px solid #67AFE1;

  .ng-arrow {
    border-color: transparent transparent #67AFE1;
    border-width: 0 7px 7px;
  }
  */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #A2A2A2;
  border-radius: 5px;
}
.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  border-color: transparent transparent #A2A2A2;
  border-width: 0 7px 7px;
}

.ng-select, .ng-select-container {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: none !important;
  border: 1px solid #A2A2A2 !important;
  border-radius: 2px;
}

.ng-select-filtered .ng-select-container.ng-has-value .ng-value-container .ng-placeholder {
  opacity: 0;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-top: 3px;
  padding-left: 4px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  height: 26px;
  background-color: #009fe3;
  color: white;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  padding-right: 5px;
}

div#driver-popover-item {
  display: none;
  position: absolute;
  background: #fff;
  color: #9F9F9F;
  margin: 0;
  padding: 15px;
  border-radius: 5px;
  max-width: 250px;
  height: auto;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.4);
  z-index: 1000000000;
}

div#driver-popover-item .driver-popover-tip {
  border: 5px solid #fff;
  content: "";
  position: absolute;
}

div#driver-popover-item .driver-popover-tip.bottom {
  bottom: -10px;
  border-color: #fff transparent transparent;
}

div#driver-popover-item .driver-popover-tip.bottom.position-center {
  left: 49%;
}

div#driver-popover-item .driver-popover-tip.bottom.position-right {
  right: 20px;
}

div#driver-popover-item .driver-popover-tip.left {
  left: -10px;
  top: 10px;
  border-color: transparent #fff transparent transparent;
}

div#driver-popover-item .driver-popover-tip.left.position-center {
  top: 46%;
}

div#driver-popover-item .driver-popover-tip.left.position-bottom {
  top: auto;
  bottom: 20px;
}

div#driver-popover-item .driver-popover-tip.right {
  right: -10px;
  top: 10px;
  border-color: transparent transparent transparent #fff;
}

div#driver-popover-item .driver-popover-tip.right.position-center {
  top: 46%;
}

div#driver-popover-item .driver-popover-tip.right.position-bottom {
  top: auto;
  bottom: 20px;
}

div#driver-popover-item .driver-popover-tip.top {
  top: -10px;
  border-color: transparent transparent #fff;
}

div#driver-popover-item .driver-popover-tip.top.position-center {
  left: 40%;
}

div#driver-popover-item .driver-popover-tip.top.position-right {
  left: 18px;
}

div#driver-popover-item .driver-popover-tip.top.position-left {
  left: 1%;
}

div#driver-popover-item .driver-popover-tip.mid-center {
  display: none;
}

div#driver-popover-item .driver-popover-footer {
  display: block;
  margin-top: 10px;
}

div#driver-popover-item .driver-popover-footer button {
  display: inline-block;
  padding: 3px 10px;
  border: 1px solid #d4d4d4;
  text-decoration: none;
  text-shadow: 1px 1px 0 #fff;
  color: #2d2d2d;
  font: 11px/normal sans-serif;
  cursor: pointer;
  outline: 0;
  background-color: #f1f1f1;
  border-radius: 2px;
  zoom: 1;
  line-height: 1.3;
}

div#driver-popover-item .driver-popover-footer button.driver-disabled {
  visibility: hidden;
  left: 5%;
  color: grey;
  cursor: default;
  pointer-events: none;
}

div#driver-popover-item .driver-popover-footer .driver-close-btn {
  position: absolute;
  background-image: url(/assets/images/icons/icon-close.svg);
  top: 17px;
  right: 15px;
  height: 28px;
  width: 25px;
  background-color: white;
  border: none;
}

div#driver-popover-item .driver-popover-footer .driver-prev-btn {
  float: left;
  left: 5%;
  background-image: url(/assets/images/icons/icon-previous.svg);
  height: 38px;
  background-color: white;
  width: 38px;
  border: none;
}

div#driver-popover-item .driver-popover-footer .driver-next-btn {
  background-image: url(/assets/images/icons/icon-next.svg);
  height: 38px;
  background-color: white;
  width: 38px;
  border: none;
  right: 20px;
}

div#driver-popover-item .driver-popover-footer .driver-btn-group, div#driver-popover-item .driver-popover-footer .driver-close-only-btn {
  float: right;
}

.driver-navigation-btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

div#driver-popover-item .driver-popover-title {
  font: 21px/normal "Poppins", "sans-serif";
  margin: 0 0 5px;
  display: block;
  position: relative;
  line-height: 1.2;
  width: calc(100% - 35px);
  word-break: break-word;
}

div#driver-popover-item .driver-popover-description {
  position: relative;
  margin-bottom: 0;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: 18px;
  line-height: 1.5;
  color: #373E65;
  font-weight: 400;
  zoom: 1;
}

.driver-clearfix:after, .driver-clearfix:before {
  content: "";
  display: table;
}

.driver-clearfix:after {
  clear: both;
}

.driver-stage-no-animation {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  background: transparent !important;
  outline: 5000px solid rgba(0, 0, 0, 0.75);
}

div#driver-page-overlay {
  background: #000;
  position: fixed;
  bottom: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
  zoom: 1;
  filter: alpha(opacity=45);
  opacity: 0.45 !important;
  z-index: 100002 !important;
}

div#driver-highlighted-element-stage, div#driver-page-overlay {
  top: 0;
  left: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

div#driver-highlighted-element-stage {
  position: absolute;
  height: 50px;
  width: 300px;
  background: #fff;
  z-index: 100003 !important;
  display: none;
  border-radius: 2px;
}

.driver-highlighted-element {
  z-index: 100004 !important;
}

.driver-position-relative {
  position: relative !important;
}

.driver-fix-stacking {
  z-index: auto !important;
  opacity: 1 !important;
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
  transform: none !important;
  -webkit-filter: none !important;
  -moz-filter: none !important;
  -ms-filter: none !important;
  -o-filter: none !important;
  filter: none !important;
  -webkit-perspective: none !important;
  -moz-perspective: none !important;
  -ms-perspective: none !important;
  -o-perspective: none !important;
  perspective: none !important;
  -webkit-transform-style: flat !important;
  -moz-transform-style: flat !important;
  -ms-transform-style: flat !important;
  transform-style: flat !important;
  -webkit-transform-box: border-box !important;
  -moz-transform-box: border-box !important;
  -ms-transform-box: border-box !important;
  -o-transform-box: border-box !important;
  transform-box: border-box !important;
  will-change: unset !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

body {
  font-family: "Poppins" !important;
  color: #262D5F !important;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}